import shortid = require('shortid')
import picomodal = require('picomodal')
import wrapPromise = require('@braintree/wrap-promise')
import '../polyfill'
import { whitelist as WHITELIST, defaultConfiguration as DEFAULT_CONFIGURATION } from '../config/configuration'
import { USER_EVENTS, EVENTS } from '../library/events'
import htmlClass from '../library/html-class'
import { style } from '../config/style'
import { PubSub } from '../library/pubsub'
import { LibraryError } from '../library/libraryerror'
import { EventEmitter } from '../library/event-emitter'

const KEY_CODE_ENTER = 13
const version = process.env.ifs_version
const htmlPath = '/web-ifs/assets/' + version + '/'

/**
 * Creates a new instance
 * @constructor
 * @param   {Options} options required initialization options
 * @public
 * @class
 */
export class Integration extends EventEmitter {
  private _options: Options
  private _fsId = shortid.generate()
  private _isActive = true
  private _valid: any = {}
  private _pubSub: PubSub
  private tdsTimeout: NodeJS.Timeout

  private readonly onError = (msg: string | Event, url: string, line: number, col: number, error: Error): void => {
    // LibraryError are not captured properly by this
    const isLibraryError = msg === "Script error." && error === null && line === 0 && col === 0
    if (!isLibraryError) {
      this._pubSub.publish(EVENTS.REPORT_ERROR, { msg, url, line, col, error })
    }
  }

  constructor(options: Options) {
    super()
    this._options = options

    // configuration is a base64 encoded entity created in web-api
    this.checkOptions(options)
    this._options.configuration = JSON.parse(atob(decodeURIComponent(options.clientConfiguration)))
    this.checkMeansOfPayment(options)
    this.checkTds20RequiredFields(options)

    //This is an information about other instances
    if (global.instances.length > 0) {
      this._isActive = false
      console.warn('Current instance with id ' + this._fsId + ' for ' + options.type)
      for (let i in global.instances) {
        let instance_options = global.instances[i]._options
        let instance_type = instance_options !== undefined ? instance_options.type : 'unknown'
        console.warn('There is another instance with id ' + global.instances[i]._fsId + ' for type ' + instance_type)
      }
    }

    console.log('Current fsId: ' + this._fsId)

    this.initPubSub()
    this.subscribeEvents()
    this.createIFrames(options)
    this.registerDefaultHandlers()

    window.onerror = this.onError
  }

  private initPubSub() {
    this._pubSub = new PubSub({
      fsId: this._fsId,
      merchantUrl: location.href
    })
    const err = LibraryError.create(
      LibraryError.categories.INTEGRATION,
      LibraryError.types.CREATE_MISSING_PROPERTY,
      "Couldn't find the '' property of options."
    )
    try {
      this._pubSub.subscribe(EVENTS.EXCEPTION, (e) => {
        this.publish(EVENTS.REPORT_ERROR, err)
        throw err
      })
    } catch (e) {
      this.publish(EVENTS.REPORT_ERROR, err)
      throw err
    }
  }
  /**
   * Check the options for the expected properties. If there is an error a LibraryError is thrown.
   * This is necessary here because later we have no chance to throw the exceptions in the 'Promise' scope
   *
   * @private
   * @param {*} options
   * @memberof Integration
   */
  private checkOptions(options) {
    let requiredProperties = ['clientSession', 'clientConfiguration', 'type', 'fields']

    //checks for unavailable options
    if (options === null || options === undefined) {
      const err = LibraryError.create(
        LibraryError.categories.INTEGRATION,
        LibraryError.types.CREATE_CONFIGURATION_MISSING,
        'Configuration is missing.'
      )
      this.publish(EVENTS.REPORT_ERROR, err)
      throw err
    }

    //checks for missing property
    requiredProperties.forEach((property) => {
      if (options[property] === undefined) {
        const err = LibraryError.create(
          LibraryError.categories.INTEGRATION,
          LibraryError.types.CREATE_MISSING_PROPERTY,
          "Couldn't find the '" + property + "' property of options."
        )
        this.publish(EVENTS.REPORT_ERROR, err)
        throw err
      }
    })

    //checks client session
    if (
      typeof options.clientSession !== 'string' ||
      (typeof options.clientSession === 'string' && !/^[-,a-zA-Z0-9]{20,128}$/.test(options.clientSession))
    ) {
      const err = LibraryError.create(
        LibraryError.categories.INTEGRATION,
        LibraryError.types.CREATE_INVALID_PROPERTY,
        'Client session id seems to be invalid.'
      )
      this.publish(EVENTS.REPORT_ERROR, err)
      throw err
    }

    //checks client configuration
    if (
      typeof options.clientConfiguration !== 'string' ||
      (typeof options.clientConfiguration === 'string' && !/^[-,a-zA-Z0-9%=]+$/.test(options.clientConfiguration))
    ) {
      const err = LibraryError.create(
        LibraryError.categories.INTEGRATION,
        LibraryError.types.CREATE_INVALID_PROPERTY,
        'Client configuration seems to be invalid.'
      )
      this.publish(EVENTS.REPORT_ERROR, err)
      throw err
    }

    //checks payment type
    let availableTypes = Object.keys(DEFAULT_CONFIGURATION)
    if (!availableTypes.includes(options.type)) {
      const err = LibraryError.create(
        LibraryError.categories.INTEGRATION,
        LibraryError.types.CREATE_UNSUPPORTED_PAYMENT_TYPE,
        'The given type is not supported yet. Only ' + availableTypes + ' are supported.'
      )
      this.publish(EVENTS.REPORT_ERROR, err)
      throw err
    }

    //checks required fields
    {
      let defaultFields = DEFAULT_CONFIGURATION[options.type].fields
      let optionFields = Object.keys(options.fields)
      let requiredFields = Object.keys(defaultFields).filter((key) => {
        // Check only fields present in options object.
        // This is needed because MOTO credit card transactions have no field code, but
        // regular ECOM credit card transactions have field code, that is required.
        return options.fields[key] && defaultFields[key].required === true
      })

      optionFields.filter((key) => {
        requiredFields.forEach((key2, i) => {
          if (key === key2) requiredFields.splice(i, 1)
        })
      })

      if (requiredFields.length > 0) {
        const err = LibraryError.create(
          LibraryError.categories.INTEGRATION,
          LibraryError.types.CREATE_MISSING_REQUIRED_FIELD,
          'Some fields (' + requiredFields + ') that are required are not set.'
        )
        this.publish(EVENTS.REPORT_ERROR, err)
        throw err
      }
    }

    //checks selector
    Object.keys(options.fields).forEach((key) => {
      if (options.fields[key].selector === undefined || typeof options.fields[key].selector !== 'string') {
        const err = LibraryError.create(
          LibraryError.categories.INTEGRATION,
          LibraryError.types.CREATE_INVALID_SELECTOR,
          "The 'selector' property for field '" + key + "' is missing."
        )
        this.publish(EVENTS.REPORT_ERROR, err)
        throw err
      } else {
        let selector = options.fields[key].selector
        let element = document.querySelector(selector)
        if (element === null || element === undefined) {
          const err = LibraryError.create(
            LibraryError.categories.INTEGRATION,
            LibraryError.types.CREATE_INVALID_SELECTOR,
            "The element of the selector '" + selector + "' does not exists."
          )
          this.publish(EVENTS.REPORT_ERROR, err)
          throw err
        }
      }
    })

    let paymentType = options.type

    //check for modified field properties
    Object.keys(options.fields).forEach((field) => {
      let typeOptions = options.fields

      if (typeOptions !== undefined) {
        let userOptions = typeOptions[field]
        let whiteListedFields = WHITELIST[paymentType].fields[field]

        if (whiteListedFields !== undefined && Array.isArray(whiteListedFields)) {
          if (userOptions !== undefined) {
            Object.keys(userOptions).forEach((field) => {
              if (!whiteListedFields.includes(field)) {
                const err = LibraryError.create(
                  LibraryError.categories.INTEGRATION,
                  LibraryError.types.CREATE_MODIFICATIONS_NOT_ALLOWED,
                  "Property '" + field + "' for '" + paymentType + "' is not allowed for modifications."
                )
                this.publish(EVENTS.REPORT_ERROR, err)
                throw err
              }
            })
          }
        }
      }
    })

    //Check styles
    if (options.styles !== undefined) {
      Object.keys(options.styles).forEach((selector) => {
        let selectorStyle = options.styles[selector]
        Object.keys(selectorStyle).forEach((property) => {
          if (!WHITELIST.styles.includes(property)) {
            const err = LibraryError.create(
              LibraryError.categories.INTEGRATION,
              LibraryError.types.CREATE_STYLE_NOT_ACCEPTED,
              "CSS property '" + property + "' is not whitelisted."
            )
            this.publish(EVENTS.REPORT_ERROR, err)
            throw err
          }
        })
      })
    }
  }
  /**
   *
   *
   * @private
   * @param {*} options
   * @memberof Integration
   */
  private checkTds20RequiredFields(options) {
    if (
      options.type === 'creditCard' &&
      options.configuration &&
      options.configuration.tds20 === true &&
      !options.fields.holder
    ) {
      const err = LibraryError.create(
        LibraryError.categories.INTEGRATION,
        LibraryError.types.CREATE_MISSING_REQUIRED_FIELD,
        'Field holder is required but not set for 3D-Secure 2.0.'
      )
      this.publish(EVENTS.REPORT_ERROR, err)
      throw err
    }
  }
  /**
   * Checks the means of payment after the options are decoded.
   * @param options options to check
   *
   * @private
   * @memberof Integration
   */
  private checkMeansOfPayment(options) {
    let paymentType = options.type

    let acceptedMeansOfPayment = ((options.configuration || {}).options || {}).acceptedMeansOfPayment
    if (acceptedMeansOfPayment === undefined || acceptedMeansOfPayment.length === 0) {
      const err = LibraryError.create(
        LibraryError.categories.INTEGRATION,
        LibraryError.types.CREATE_UNSUPPORTED_PAYMENT_TYPE,
        'Payment types are not configured.'
      )
      this.publish(EVENTS.REPORT_ERROR, err)
      throw err
    }
    else {
      if (paymentType === 'bankAccount' && !acceptedMeansOfPayment.includes('elv')) {
        const err = LibraryError.create(
          LibraryError.categories.INTEGRATION,
          LibraryError.types.CREATE_UNSUPPORTED_PAYMENT_TYPE,
          "Payment type '" + paymentType + "' is not configured."
        )
        this.publish(EVENTS.REPORT_ERROR, err)
        throw err
      } else if (
        // acceptedMeansOfPayment has additional 'creditcard' flag (was 'visa', 'amex', ...) to pass this test [MII-2885]
        paymentType === 'creditCard' &&
        !(acceptedMeansOfPayment.includes('creditcard') || acceptedMeansOfPayment.includes('maestro'))
      ) {
        const err = LibraryError.create(
          LibraryError.categories.INTEGRATION,
          LibraryError.types.CREATE_UNSUPPORTED_PAYMENT_TYPE,
          "Payment type '" + paymentType + "' is not configured."
        )
        this.publish(EVENTS.REPORT_ERROR, err)
        throw err
      }
    }
  }

  private subscribeEvents() {
    //Field count
    let fieldCount = Object.keys(this._options.fields).length
    //Subscribe frame ready
    this._pubSub.subscribe(EVENTS.FRAME_READY, (reply) => {
      if (0 === fieldCount--) {
        if (reply) {
          reply(this._options)
        }
        this._emit(USER_EVENTS.ready)
      }
    })

    //Subscribe cardBrandChange (propagate)
    this._pubSub.subscribe(
      EVENTS.CARD_BRAND_CHANGE,
      (cardBrandChange) => {
        this._emit(USER_EVENTS.cardBrandChange, cardBrandChange)
      }
    )

    //Subscribe ibanTypeChange (propagate)
    this._pubSub.subscribe(
      EVENTS.IBAN_TYPE_CHANGE,
      (ibanTypeChange) => {
        this._emit(USER_EVENTS.ibanTypeChange, ibanTypeChange)
      }
    )

    //Subscribe validationChange (propagate)
    this._pubSub.subscribe(
      EVENTS.VALIDATION_CHANGE,
      (validationChange) => {
        this._emit(USER_EVENTS.validationChange, validationChange)
      }
    )

    //Subscribe formValidationChange (propagate)
    this._pubSub.subscribe(
      EVENTS.FORM_VALIDATION_CHANGE,
      (validationChange) => {
        this._valid = validationChange
        this._emit(USER_EVENTS.formValidationChange, validationChange)
      }
    )

    //Subscribe special key
    this._pubSub.subscribe(
      EVENTS.SPECIAL_KEY_PRESSED,
      (keyPressed) => {
        if (keyPressed.key === KEY_CODE_ENTER) {
          this._emit(USER_EVENTS.submitRequest, keyPressed)
        }
      }
    )

    //Subscribe focus changed (changed activation of form instance)
    this._pubSub.subscribe(
      EVENTS.FOCUS_CHANGED,
      (focus) => {
        this._emit(USER_EVENTS.focusChange, focus)

        if (!focus.focused) return

        for (let i in global.instances) {
          let instance = global.instances[i]
          let obj = { isActive: instance._fsId === this._fsId }
          let valueChanged = global.instances[i]._isActive !== obj.isActive
          global.instances[i]._isActive = obj.isActive
          global.instances[i]._emit(USER_EVENTS.activeStateChange, obj)
          if (global.instances[i]._isActive === true && valueChanged) {
            //inform the form that active state has changed
            this._pubSub.publish(EVENTS.ACTIVE_STATE_CHANGE, obj)
          }
        }
      }
    )

    this._pubSub.subscribe(EVENTS.START_TIMEOUT, () => {
      this.tdsTimeout = setTimeout(() => {
        this._pubSub.publish(EVENTS.RETURN_FROM_METHOD, {
          tdsMethodCanceled: true,
        })
      }, 5000);
    })
    this._pubSub.subscribe(EVENTS.STOP_TIMEOUT, () => {
      clearTimeout(this.tdsTimeout)
    })

    if (process.env.DEV) {
      //Subscribe form input (and propagate)
      this.on(
        // @ts-ignore
        USER_EVENTS.formInput,
        (input) => {
          this._pubSub.publish(EVENTS.FORM_INPUT, input)
          this._pubSub.publish(EVENTS.FOCUS_CHANGED, {
            focused: true
          })
        }
      )
    }
  }

  /**
   * Create all IFrames in the context that is described in options.
   * @param options the necessary options
   * @private
   * @memberof Integration
   */
  private createIFrames(options) {
    Object.keys(options.fields).forEach(
      (key) => {
        console.log('initializing field ' + key)
        const field = options.fields[key]
        const container = document.querySelector(field.selector)
        this.createIFrame(options.configuration.host, container, key, this._fsId)
      }
    )

    setTimeout(() => { this._pubSub.publish(EVENTS.FRAME_READY) }, 0)
  }

  /**
   * Create an IFrame and inject it into the container scope.
   *
   * @param host the host address
   * @param container the container to inject the IFrame
   * @param id the id of the field
   * @param fsId the fs Id
   * @private
   */
  private createIFrame(host, container, id, fsId) {
    let iframe = document.createElement('iframe') as any

    iframe.src = 'about:blank'
    iframe.scrolling = 'no'
    iframe.allowtransparency = true
    iframe.name = id
    iframe.id = 'idfield' + id

    iframe.frameBorder = '0'
    iframe.borderStyle = 'none'
    iframe.width = '100%'
    iframe.height = '100%'
    iframe.float = 'left'

    this.injectIFrame(iframe, container)

    // assumption (cd): uses timeout to make frame loading async
    setTimeout(() => {
      iframe.src = host + htmlPath + 'in-iframe.html#' + fsId
    }, 0)
  }

  /**
   * Inject the IFrame into to container scope.
   *
   * @param frame the frame to inject
   * @param container the container to inject
   * @private
   * @memberof Integration
   */
  private injectIFrame(frame, container) {
    let div = document.createElement('div')
    let fragment = document.createDocumentFragment()
    div.style.clear = 'both'

    fragment.appendChild(frame)
    fragment.appendChild(div)
    container.appendChild(fragment)
  }

  /**
   * Creates the response object for {@link Integration~createToken}
   *
   * @private
   * @param {*} createTokenResponse
   * @param {*} authLevel
   * @return {*}  {(CreateTokenResponse | void)}
   * @memberof Integration
   */
  private createTokenResponse(createTokenResponse, authLevel): CreateTokenResponse | void {
    let result = {
      token: createTokenResponse.token,
      info: createTokenResponse.info,
      type: createTokenResponse.type
    }
    if (authLevel !== undefined) {
      // ACS response - overwrite previous value
      result.info.creditCard.tx3DSecureAuthLevel = authLevel
    }
    return result
  }

  /**
   * Register default handlers that could be override by the user but should work without any specific configuration.
   *
   * @private
   * @memberof Integration
   */
  private registerDefaultHandlers() {
    /**
     * If validation change the frame container gets the right validation style
     */
    this.on(USER_EVENTS.validationChange, (event: ValidationChangePayload) => {
      let validation = event.validation
      let selector = event.field.selector

      let element = document.querySelector(selector)
      let label = element.previousElementSibling

      toggleValidation(element, validation)
      toggleValidation(label, validation)
    })

    /**
     * If an input field is focused the frame container gets the 'focused' style.
     */
    this.on(USER_EVENTS.focusChange, (event: FocusChangePayload) => {
      if (event.field !== undefined) {
        let selector = event.field.selector
        if (selector !== undefined) {
          let element = document.querySelector(selector)
          if (element !== null) {
            htmlClass.toggle(element, style.focused, event.focused)
          }
        }
      }
    })

    /**
     * Toggle validation class.
     *
     * @param element element for which the class should be altered by validation
     * @param validation the validation (potentialValid, valid, accepted)
     * @private
     */
    function toggleValidation(element, validation) {
      if (element !== null) {
        let classPotentialValid = !validation.valid && validation.potentialValid
        htmlClass.toggle(element, style.valid, validation.valid)
        htmlClass.toggle(element, style.notAccepted, !validation.accepted && validation.potentialValid)
        htmlClass.toggle(element, style.potentialValid, classPotentialValid)
        htmlClass.toggle(element, style.invalid, !validation.isValid && !validation.potentialValid)
      }
    }
  }

  /**
   * Returns true if integration instance is active. This is only necessary if you use more than one
   * integration instance (i.e. credit card and bank account form).
   *
   * @public
   * @method
   * @return {boolean} true if instance is active otherwise false
   * @memberof Integration
   */
  isActive() {
    return this._isActive
  }

  /**
   * Returns the state of validation. A form is valid of all required fields are completed,
   * the payment type is accepted and all fields are valid.
   *
   * @returns {boolean} true if form is valid otherwise false
   * @public
   * @method
   * @memberof Integration
   */
  isValid() {
    let valid = this._valid.valid === true && this._valid.completed === true && this._valid.accepted === true
    if (!valid) {
      if (!this._valid.valid) console.warn('The form is not valid. Some fields are not in valid form.')
      if (!this._valid.completed) console.warn('The form is not completed. Some fields are required that has no value.')
      if (!this._valid.accepted) console.warn('The payment type is not accepted.')
    }

    return valid
  }

  /**
   * Create a credit card or a bank details token. The result is a {@link Promise} with a good and bad path.
   *
   * @public
   * @memberof Integration
   * @method
   * @param {?object} [createTokenOptions] createTokenOptions additional options for token creation
   * @returns {Promise<CreateTokenResponse>}
   */
  createToken(createTokenOptions: CreateTokenOptions): Promise<CreateTokenResponse | void> {
    let self = this

    if (!createTokenOptions) {
      createTokenOptions = {}
    }

    // https://zellwk.com/blog/js-promises/
    return new Promise((resolve, reject) => {
      if (!self.isActive()) return
      if (!self.isValid()) {
        const err = LibraryError.create(
          LibraryError.categories.USER,
          LibraryError.types.FORM_FIELDS_INVALID,
          'The form is invalid. Please correct.'
        )
        this.publish(EVENTS.REPORT_ERROR, err)
        throw err
      }

      // answered by createTokenizationHandler in ../framed/index.js
      const tokenizationEventHandler = (response) => {
        let err = response[0]
        let createTokenResponse = response[1]

        if (err) {
          const err = new LibraryError({
            category: LibraryError.categories.COMMUNICATION,
            type: LibraryError.types.CREATETOKEN_CONNECTION_ERROR,
            detail: 'error response in server call'
          })
          this.publish(EVENTS.REPORT_ERROR, err)
          reject(err)
        } else {
          console.log('createToken response:' + JSON.stringify(createTokenResponse))
          if (createTokenResponse.forwardToACS !== undefined && createTokenResponse.forwardToACS.acsUrl !== undefined) {
            console.log('received forwardToACS - createTokenResponse: ' + JSON.stringify(createTokenResponse))

            // https://github.com/Nycto/PicoModal
            let acsFormIFrameUrl = self._options.configuration.host + htmlPath + 'forward-to-acs.html#' + self._fsId
            let acsWindowOption = self._options.acs_window
            let acsFormIFrameWidth = (acsWindowOption && acsWindowOption.width) || 400 // was fix to 400x400
            let acsFormIFrameHeight = (acsWindowOption && acsWindowOption.height) || 400
            const modal = picomodal({
              content:
                "<iframe id='tds' width='" +
                acsFormIFrameWidth +
                "' height='" +
                acsFormIFrameHeight +
                "' scrolling='auto' allowtransparency='true' frameBorder='0' src='" +
                acsFormIFrameUrl +
                "'></iframe>",
              closeButton: false,
              overlayClose: false
            })

            self._pubSub.subscribe(EVENTS.RETURN_FROM_ACS, (data) => {
              console.log('received return-from-acs - closing modal data: ' + JSON.stringify(data))
              modal.close()

              const error = data.error
              if (error) {
                console.log('return-from-acs ERROR', error)
                createTokenResponse = undefined
                const err = new LibraryError({
                  category: LibraryError.categories.PROCESSING,
                  type: error,
                  detail: 'error in 3D-secure process'
                })
                this.publish(EVENTS.REPORT_ERROR, err)
                reject(err)
              } else {
                resolve(self.createTokenResponse(createTokenResponse, data.auth))
              }
            })

            self._pubSub.subscribe(EVENTS.FORWARD_TO_ACS, (reply) => {
              console.log('received forward-to-acs')
              reply(createTokenResponse.forwardToACS)
            })

            modal.show()
          } else if (
            createTokenResponse.tds20MethodRequired !== undefined &&
            createTokenResponse.tds20MethodRequired.tdsMethodURL !== undefined
          ) {
            console.log('received tds20MethodRequired - createTokenResponse: ' + JSON.stringify(createTokenResponse))

            let methodFormIFrameUrl =
              self._options.configuration.host + htmlPath + 'forward-to-method.html#' + self._fsId
            let methodIFrame = document.createElement('iframe')

            methodIFrame.width = '0'
            methodIFrame.height = '0'
            methodIFrame.src = methodFormIFrameUrl

            methodIFrame.name = 'tdsMethodIFrame'
            methodIFrame.setAttribute('frameborder', '0')
            methodIFrame.setAttribute('border', '0')

            methodIFrame.scrolling = 'no'
            methodIFrame.setAttribute('seamless', 'seamless')
            methodIFrame.setAttribute('style', 'display:none;')
            methodIFrame.setAttribute('tabindex', '-1')

            self._pubSub.subscribe(EVENTS.RETURN_FROM_METHOD, (data) => {
              console.log('received return-from-method - closing iFrame: ' + JSON.stringify(data))

              document.body.removeChild(methodIFrame)
              createTokenOptions.holder = ''
              const error = data.error
              if (error) {
                console.log('return-from-method ERROR', error)
                createTokenResponse = undefined
                const err = new LibraryError({
                  category: LibraryError.categories.PROCESSING,
                  type: error,
                  detail: 'error in 3D-secure method process'
                })
                this.publish(EVENTS.REPORT_ERROR, err)
                reject(err)
              } else {
                if (data.tdsMethodCanceled) {
                  createTokenOptions.tdsMethodCanceled = data.tdsMethodCanceled
                }
                self._pubSub.publish(EVENTS.TOKENIZATION_REQUEST, createTokenOptions, tokenizationEventHandler)
              }
            })

            self._pubSub.subscribe(EVENTS.FORWARD_TO_METHOD, (reply) => {
              console.log('received forward-to-method')
              reply(createTokenResponse.tds20MethodRequired)
            })

            document.body.appendChild(methodIFrame)
          } else {
            const responseError = createTokenResponse.error
            if (responseError !== undefined) {
              createTokenResponse = undefined
              const err = new LibraryError({
                category: LibraryError.categories.PROCESSING,
                type: responseError,
                detail: 'error response in server call'
              })
              this.publish(EVENTS.REPORT_ERROR, err)
              reject(err)
            } else {
              resolve(self.createTokenResponse(createTokenResponse, undefined))
            }
          }
        }
      }
      self._pubSub.publish(EVENTS.TOKENIZATION_REQUEST, createTokenOptions, tokenizationEventHandler)
    })
  }

  /**
   * Clear all input fields that are associated with the instance
   * @public
   * @method
   * @memberof Integration
   */
  clearFields() {
    this._pubSub.publish(EVENTS.CLEAR_FIELDS)
  }


}

export default wrapPromise.wrapPrototype(Integration)
